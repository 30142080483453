import React from 'react'
import { Link } from 'gatsby'

export default function Alert({ type, msg, link, linkText }) {
    return (
        <div className={`${type === 'success' && 'bg-green-200'} ${type === 'error' && 'bg-red-200'}`}>
            <div className="py-2 px-4 flex items-center">
                <p className={`${type === 'success' && 'text-green-800'} ${type === 'error' && 'text-red-800'}`}>{msg}</p>
                { link !== '' && linkText !== '' && <Link to={link} className='underline ml-2'>{linkText}</Link> }
            </div>
        </div>
    )
}