import React from 'react'
import { Link } from 'gatsby'
import { SearchProvider } from '../../context/search-provider'
import { useMutation } from 'urql'
import Alert from '../../components/utils/Alert'
import { Layout } from "../../components/layout/layout"

const ResetPassword = `
mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
        customerUserErrors {
            code
            field
            message
        }
    }
}`

function ResetPage() {
    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [alert, setAlert] = React.useState(undefined)
    
    const [resetPasswordResult, resetPassword] = useMutation(ResetPassword)

    const reset = async (e) => {
        e.preventDefault()
        setAlert(undefined)
        try {
            if (email === '') return

            setLoading(true)
            const variables = { email: email }
            const { data: { customerRecover }, error } = await resetPassword(variables)

            console.log('[error]', error)

            if (error) {
                throw new Error(error.message)
            }
            else if (customerRecover.customerUserErrors.length) {
                const [firstError] = customerRecover.customerUserErrors
                throw new Error(firstError.message)
            }
            else {
                setLoading(false)
                setAlert({
                    type: 'success',
                    message: 'Password reset link has been sent via email.',
                    link: '',
                    linkText: ''
                })
            }
        } catch (error) {
            setLoading(false)
            setAlert({
                type: 'error',
                message: error.message,
                link: '',
                linkText: ''
            })
            console.error(error)
        }
    }

    return (
        <Layout>
            <div className="w-full mx-auto" id="page-wrapper">

                <div className="hero w-full bg-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-20 px-5 relative">
                    <div className="text-4xl font-bold w-full text-center">
                        <h1 className="leading-tight font-normal text-45xl">Reset your password</h1>
                    </div>
                </div>

                { alert && <div className='pt-4 px-4'><Alert type={alert.type} msg={alert.message} link={alert.link} linkText={alert.linkText} /></div> }

                <div className="form-body flex flex-col mb-4 w-full mx-auto py-4 px-4 max-w-2xl relative">
                    {
                        loading && (
                            <div className="absolute bg-background-overlay left-0 top-0 w-full h-full flex items-center justify-center z-50">
                                <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                            </div>
                        )
                    }

                    <form action="" method="post" onSubmit={e => reset(e)}>
                        <div className="form-group mb-4">
                            <label htmlFor="email" className="block mb-1 uppercase">Email</label>
                            <input
                                type="text" 
                                name="email" 
                                id="email" 
                                className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal" 
                                required
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="button-group mt-4">
                            <button
                                type="submit"
                                className="button w-full sm:w-auto items-center justify-center bg-primary border border-primary text-white py-4 px-8 uppercase text-sm tracking-widest font-bold mb-4">
                                <span>Reset Password</span>
                            </button>
                            <Link to="/account/login/" className="block text-primary text-center sm:text-left font-normal underline">Cancel</Link>
                        </div>
                    </form>
                </div>

            </div>
        </Layout>
    )
}

export default function ResetPageTemplate(props) {
    return (
        <SearchProvider>
            <ResetPage {...props} />
        </SearchProvider>
    )
}